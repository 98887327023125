

// images
import TopBackground from "../images/ispoHeader.jpeg";

import galleryImage1 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-1.jpg"
import galleryImage2 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-2.jpg"
import galleryImage3 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-3.jpg"
import galleryImage4 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-4.jpg"
import galleryImage5 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-5.jpg"
import galleryImage6 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-6.jpg"
import galleryImage7 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-7.jpg"
import galleryImage8 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-8.jpg"
import galleryImage9 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-9.jpg"
import galleryImage12 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-12.jpg"
import galleryImage11 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-11.jpg"
import galleryImage13 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-13.jpg"
import galleryImage14 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-14.jpg"
import galleryImage15 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-15.jpg"
import galleryImage16 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-16.jpg"
import galleryImage17 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-17.jpg"
import galleryImage18 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-18.jpg"
import galleryImage19 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-19.jpg"
import galleryImage20 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-20.jpg"
import galleryImage21 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-21.jpeg"
import galleryImage22 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-22.jpeg"
import galleryImage23 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-23.jpeg"
import galleryImage24 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-24.jpeg"
import galleryImage25 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-25.jpeg"
import galleryImage26 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-26.jpeg"
import galleryImage27 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-27.jpeg"
import galleryImage28 from "../images/digital-textrends-hub-gallery/gl-digital-textrends-hub-28.jpeg"

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("digital-textrends-hub.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: false,
      opacity: false,
      full: true,
      image: {
        src: TopBackground,
        smallestHeight: true,
        center: true,
        alt: t("digital-textrends-hub.imgAlt"),
      },
    },
    firstText: [
      {
        text: t("digital-textrends-hub.aboutIspo"),
        colorClass: "colorDark",
        textType: "title",
      },
      {
        text: t("digital-textrends-hub.designersP1"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "pt-15 font17 lineHeight2",
      },
      {
        text: t("digital-textrends-hub.designersP2"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "pt-15 font17 lineHeight2",
      },
    ],
    firstBox: [
      {
        text: t("digital-textrends-hub.box1Title"),
        colorClass: "colorDark",
        textType: "title",
        extraClass: "w-100 pr-5 pl-5"
      },
      {
        text: t("digital-textrends-hub.box1Text"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "w-100 font20 pr-5 pl-5"
      }
    ],
    secondBox: [
      {
        text: t("digital-textrends-hub.box2Title"),
        colorClass: "colorDark",
        textType: "title",
        extraClass: "w-100 pr-5 pl-5"
      },
      {
        text: t("digital-textrends-hub.box2Text"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "w-100 font20 pr-5 pl-5"
      }
    ],
    thirdBox: [
      {
        text: t("digital-textrends-hub.box3Title"),
        colorClass: "colorDark",
        textType: "title",
        extraClass: "w-100 pr-5 pl-5"
      },
      {
        text: t("digital-textrends-hub.box3Text"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "w-100 font20 pr-5 pl-5"
      }
    ],
    galleryTitle: [
      {
        text: t("digital-textrends-hub.galleryTitle"),
        colorClass: "colorDark",
        textType: "title",
        extraClass: "text-white w-100 m-10 pb-30"
      }
    ],
    lightboxImages: [
      { thumbnail: galleryImage1, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage1 },
      { thumbnail: galleryImage2, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage2 },
      { thumbnail: galleryImage3, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage3 },
      { thumbnail: galleryImage4, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage4 },
      { thumbnail: galleryImage5, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage5 },
      { thumbnail: galleryImage6, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage6 },
      { thumbnail: galleryImage7, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage7 },
      { thumbnail: galleryImage8, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage8 },
      { thumbnail: galleryImage9, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage9 },
      { thumbnail: galleryImage11, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage11 },
      { thumbnail: galleryImage12, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage12 },
      { thumbnail: galleryImage13, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage13 },
      { thumbnail: galleryImage14, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage14 },
      { thumbnail: galleryImage15, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage15 },
      { thumbnail: galleryImage16, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage16 },
      { thumbnail: galleryImage17, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage17 },
      { thumbnail: galleryImage18, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage18 },
      { thumbnail: galleryImage19, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage19 },
      { thumbnail: galleryImage20, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage20 },
      { thumbnail: galleryImage21, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage21 },
      { thumbnail: galleryImage22, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage22 },
      { thumbnail: galleryImage23, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage23 },
      { thumbnail: galleryImage24, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage24 },
      { thumbnail: galleryImage25, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage25 },
      { thumbnail: galleryImage26, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage26 },
      { thumbnail: galleryImage27, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage27 },
      { thumbnail: galleryImage28, alt: t("digital-textrends-hub.galleryAlt"), src: galleryImage28 },
    ],
    hexagonTitle: [
      {
        text: t("digital-textrends-hub.hexagonTitle"),
        colorClass: "colorDark",
        textType: "title",
        extraClass: "text-white w-100 m-10 pb-30"
      }
    ],
    hexagon1: [
      {
        text: t("digital-textrends-hub.hexagon1"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "text-white w-100 m-10"
      }
    ],
    hexagon2: [
      {
        text: t("digital-textrends-hub.hexagon2"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "text-white w-100 m-10"
      }
    ],
    hexagon3: [
      {
        text: t("digital-textrends-hub.hexagon3"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "text-white w-100 m-10"
      }
    ],
    hexagon4: [
      {
        text: t("digital-textrends-hub.hexagon4"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "text-white w-100 m-10"
      }
    ],
    hexagon5: [
      {
        text: t("digital-textrends-hub.hexagon5"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "text-white w-100 m-10"
      }
    ],
    bottomText: [
      {
        text: t("digital-textrends-hub.bottomText"),
        colorClass: "colorDark",
        textType: "title",
        extraClass: "text-white w-100 m-10"
      }
    ],
    ctaLogIn:t("digital-textrends-hub.ctaLogIn"),
    ctaSignUp:t("digital-textrends-hub.ctaSignUp")
  };
};
