/**
 * Foursource page
 * https://foursource.com/digital-textrends-hub
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";
import { Link } from "gatsby";
import { pageTexts } from "../content/digital-textrends-hub";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Text from "../components/text";
import BackgroundImage from "../components/backgroundImg";
import Utm from "../components/_global/utm";

//imgs
import box1 from "../images/textrends/box-1.svg";
import box2 from "../images/textrends/box-2.svg";
import box3 from "../images/textrends/box-3.svg";

const SMALL_WINDOW = 1920;

class apparelManufacturersPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.state = {
      smallWindow: true,
    };
  }
  
  useEffect= () => {
    this.setState({
      smallWindow: window.innerWidth <= SMALL_WINDOW ? true : false,
    });
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth < SMALL_WINDOW ? true : false; ;
    this.setState({
      smallWindow: show,
    });
  };

  render() {
    return (
      <>
        <Layout pageName="digital-textrends-hub" textrends={true}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className={(this.state.smallWindow ? "paddingSidesBig" : "paddingSidesBigger") + " pb-30 pt-30 text-center"}>
            <Text text={this.text.firstText}></Text>
          </div> <div className="ispoButtons d-flex justify-content-center flex-wrap">
            <Link to={process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`)}>
              <button className="textrendsButton m-20">
              <svg className="arrow-icon mr-10 mb-1" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.16001 0L4.91901 0.717L8.11301 6.018L5.19601 11.244L6.49101 11.977L6.52101 11.924L9.81301 6.034" fill="white"></path>
                <path d="M0 5.254L0.008 6.695L8.611 6.737L8.603 5.301" fill="white"></path>
              </svg>
                {this.text.ctaSignUp}</button>
            </Link>
            <Link to={process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/auth/` : Utm(`${process.env.APP_URL}/auth/`)}>
              <button className="textrendsButton login m-20">
              <svg  className="arrow-icon mr-10 mb-1" xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><g id="Page-1" stroke="none" strokeWidth="1"><g id="arrow">
                <path id="Shape" d="M6.162.023L4.921.74l3.194 5.301-2.917 5.226L6.493 12l.03-.053 3.292-5.89" fill="#309cae;"></path><path id="Shape" d="M.002 5.277L.01 6.718l8.603.042-.008-1.436" fill="#309cae;" ></path>
                </g></g></svg>
                {this.text.ctaLogIn}</button>
            </Link>
          </div>
          <div className={(this.state.smallWindow ? "paddingSidesBig" : "paddingSidesBigger") + " d-flex justify-content-between pt-30 pb-30 flex-wrap"}>
            <div>
                <div
                className="position-relative d-flex flex-column justify-content-around m-10 text-center p-10 w-100"
                style={{
                  height: "420px",
                }}
              >
                <BackgroundImage img={box1} text={this.text.firstBox}></BackgroundImage>
              </div>
            </div>
            <div>
              <div
                className="position-relative d-flex flex-column justify-content-around m-10 text-center p-10 w-100"
                style={{
                  height: "420px",
                }}
              >
                <BackgroundImage img={box2} text={this.text.secondBox}></BackgroundImage>
              </div>
            </div>
            <div>
            <div
              className="position-relative d-flex flex-column justify-content-around m-10 text-center p-10 w-100"
              style={{
                height: "420px",
              }}
            >
              <BackgroundImage img={box3} text={this.text.thirdBox}></BackgroundImage>
            </div>
            </div>
            
          </div>
          <div className={(this.state.smallWindow ? "paddingSidesBig" : "paddingSidesBigger") +" m-30 text-center pt-30 pb-30"}>
            <Text text={this.text.bottomText}></Text>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(apparelManufacturersPage);
