import * as React from "react";
import Text from "./text";


class BackgroundImage extends React.Component {

  render() {

    const {img, text} = this.props;

    return (
      <div className="backgroundImageSection h-100 d-flex flex-column justify-content-start mt-30 pt-20" style={{
        backgroundImage: `url(${img})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}>
        <div style={{
          maxWidth: "260px",
          margin:" 0 auto",
        }}>
          <Text text={text}></Text>
        </div>
      </div>
    );
  }
}

export default BackgroundImage;
