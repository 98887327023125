import * as React from 'react';
import classNames from 'classnames';
import Parser from 'html-react-parser';

import '../style/components/textImgBackgroundFull.scss';

//components
import Bullet from './_partials/_bullet';
import Button from './_partials/_button';

class TextImgBackground extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <div
        className={classNames(
          'textImgBackgroundContent',
          'backgroundImg',
          'd-flex',
          'position-relative',
          'align-items-center',
          {
            bottomDiagonal: info.bottomDiagonal,
          }
        )}
      >
        <img
          className={classNames(
            'imgBack',
            'w-100',
            { center: info.image.center },
            { smallHeight: info.image.smallHeight },
            { height425: info.image.height425 },
            { smallestHeight: info.image.smallestHeight },
            { bottomPosition: info.image.bottomPosition },
            { bigHeight: info.image.bigHeight },
            { centerPosition: info.image.centerPosition },
            { topBorderRadius: info.image.topBorderRadius }
          )}
          src={info.image.src}
          alt={info.image.alt}
        />
        {info.opacity && (
          <div
            className={classNames('opacity position-absolute w-100 h-100', {
              topBorderRadius: info.image.topBorderRadius,
            })}
          ></div>
        )}
        {info.featuredLogo && (
          <div className='featuredLogo'>
            <a href={info.featuredLogo.url} alt='_blank'>
              <img src={info.featuredLogo.src} alt={info.featuredLogo.alt} />
            </a>
          </div>
        )}
        <div
          className={
            'textTop ' +
            (info.full ? 'paddingSidesBig text-center ' : 'notFull55 ') +
            (info.textBottom ? 'pt-250' : info.textMiddle ? 'pt-175' : 'pt-50') +
            (info.notFull70 ? ' notFull70' : '')
          }
        >
          {info.title && (
            <h1 className={info.title.colorClass + ' pb-3 ' + (info.title.extraClass ? info.title.extraClass : '')}>
              {Parser(info.title.text)}
            </h1>
          )}
          {info.subtitle && (
            <h2
              className={
                info.subtitle.colorClass + ' pb-4 ' + (info.subtitle.extraClass ? info.subtitle.extraClass : '')
              }
            >
              <span className={info.subtitle.boxShadow ? info.subtitle.boxShadow : ''}>
                {Parser(info.subtitle.text)}
              </span>
            </h2>
          )}
          {info.subtitleItems &&
            info.subtitleItems.text.map((col, i) => (
              <Bullet
                icon={info.subtitleItems.icon}
                text={col}
                colorClass={info.subtitleItems.colorClass}
                key={i}
              ></Bullet>
            ))}
          {info.cta && <Button cta={info.cta}></Button>}
        </div>
      </div>
    );
  }
}

export default TextImgBackground;
