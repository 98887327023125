import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Bullet extends React.Component {
  render() {
    const { icon, text, colorClass, url, noSpacing , centeredPage} = this.props;

    return (
      <>
        <div className={"bulletItem d-flex align-items-baseline" + (centeredPage ? ' font20 pb-3' : ' font17 pb-3')}>
          {icon.fontAwesome ? (
            <FontAwesomeIcon icon={icon.icon} className={"pr-1 width-25px " + icon.colorClass} />
          ) : (
            <img src={icon.src} alt={icon.alt} />
          )}
          {url ? (
            <a className="noUnderline" href={url} target="_blank" rel="noreferrer">
              <span className={"pl-2 hoverEffect " + colorClass + (!noSpacing && " letterSpacing1")}>{text}</span>
            </a>
          ) : (
            <span className={"pl-2 " + colorClass + (!noSpacing && " letterSpacing1")}>{text}</span>
          )}
        </div>
      </>
    );
  }
}

export default Bullet;
