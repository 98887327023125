import * as React from "react";

//components
import Paragraph from "./_partials/_paragraph";
import Bullet from "./_partials/_bullet";
import Button from "./_partials/_button";
import Title from "./_partials/_title";
import Subtitle from "./_partials/_subtitle";

class Text extends React.Component {
  render() {
    const { text } = this.props;

    return (
      <>
        {text.map((content, i) => {
          if (content.textType === "bullet") {
            return (
              <Bullet
                icon={content.icon}
                text={content.text}
                colorClass={content.colorClass}
                noSpacing={content.noSpacing ? content.noSpacing : false}
                key={i}
              ></Bullet>
            );
          }
          if (content.textType === "button") {
            return (
              <div key={i} className="row justify-content-center pt-30">
                <Button cta={content}></Button>
              </div>
            );
          }
          if (content.textType === "title") {
            return <Title key={i} title={content}></Title>;
          }
          if (content.textType === "subtitle") {
            return <Subtitle key={i} subtitle={content}></Subtitle>;
          }
          return <Paragraph key={i} text={content} />;
        })}
      </>
    );
  }
}

export default Text;
