import * as React from "react";

class Button extends React.Component {
  render() {
    const { cta } = this.props;

    return (
      <>
        <a
          href={cta.url}
          target={cta.target ? cta.target : "_self"}
          rel="noreferrer"
        >
          <button
            className={
              cta.type + " ctaButton " + (cta.extraClass ? cta.extraClass : "")
            }
          >
            {cta.svg && (
              <img src={cta.svg.src} alt={cta.svg.alt} className="mr-5" />
            )}
            {cta.text}
          </button>
        </a>
      </>
    );
  }
}

export default Button;
